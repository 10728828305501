.App {
  text-align: center;
}

button {
  font-size: 16px;
  padding: 10px 20px;
  margin: 20px;
}

.error {
  color: red;
}

.results {
  text-align: left;
  margin: 20px;
}

.bg-gradient-main {
  background: linear-gradient(151.409deg, rgba(255, 255, 255, 0.4) 0%, rgba(18, 180, 230, 0.4) 50%, rgba(220, 76, 252, 0.4) 100%);
}
